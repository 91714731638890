<template>
  <div class="CompanyDetails">
    <CModal
      :show.sync="isCompanyModified"
      :no-close-on-backdrop="true"
      title="Entreprise modifiée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre entreprise a bien été modifiée 👍
      </p>
      <p>
        Les modifications apportés seront bien repercutées sur toutes vos factures.
      </p>
      <p>
        <strong class="text-danger"><u>Attention</u></strong>, ces modifications ne sont pas répercutées sur vos modèles de conventions d'honoraires.
        Veuillez modifier <router-link to="parametres/default-convention-honoraires-b2B" target="_blank">votre convention d'honoraires B2B</router-link>
        et <router-link to="parametres/default-convention-honoraires-b2c" target="_blank">votre convention d'honoraires B2C</router-link> pour les adapter.
      </p>
      <template #footer>
        <CButton @click="$router.push('/')" color="primary">Retour à votre tableau de bord</CButton>
      </template>
    </CModal>

    <!-- MODIFY LOGO MODAL -->
    <CModal
      :show.sync="isLogoModifying"
      :no-close-on-backdrop="true"
      title="Modifier votre logo"
      size="lg"
      color="dark"
    >

      <CForm class="mt-2">
        <p>
          Chargez votre nouveau logo
        </p>
        <b-form-group class="dragdrop">
          <b-form-file
            class="text-truncate"
            browse-text='Parcourir'
            required
            accept=".jpeg, .png, .jpeg"
            v-model="modifyLogoFile"
            id="modify-logo-file-input"
            placeholder="Sélectionner ou glisser-déposer votre logo"
            drop-placeholder="Déposez votre logo ici"
            @input="$v.modifyLogoFile.$touch()"
            :state="$v.modifyLogoFile.$dirty ? !$v.modifyLogoFile.$error : null"
          ></b-form-file>
          <div class="text-danger" v-if="!$v.modifyLogoFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
          <div class="text-danger" v-if="!$v.modifyLogoFile.image_extension_validation">Attention, votre fichier doit être au format jpg, jpeg ou png</div>
          <div class="text-danger" v-if="!$v.modifyLogoFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
          <div class="text-danger" v-if="!$v.modifyLogoFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>

          <CRow class="mt-3">
            <CCol class="text-center">
              <div ref="preview" id="preview"></div>
            </CCol>
          </CRow>
        </b-form-group>

        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              color="outline-dark"
              shape="pill" block class="px-4"
              :disabled="$v.modifyLogoFile.$invalid || modifyLogoCompanyInProcess"
              @click='modifyLogoCompany'>
                Modifier votre logo
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isLogoModifying = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1>{{company.company_name}}</h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CForm>
          <CInput
            label="Nom de l'entreprise"
            v-model="modifyCompanyName" type="text"
            maxlength="254"
            @input="$v.modifyCompanyName.$touch()"
            :isValid="$v.modifyCompanyName.$dirty ? !$v.modifyCompanyName.$error : null"
          >
          </CInput>
          <CRow>
            <CCol sm="6">
              <CInput
                label="Siret"
                v-model="modifyCompanySiret" type="text"
                v-mask="'NNN NNN NNN NNNNN'"
                maxlength="17"
                @input="$v.modifyCompanySiret.$touch()"
                :isValid="$v.modifyCompanySiret.$dirty ? !$v.modifyCompanySiret.$error : null"
                invalid-feedback="Le numéro de SIRET doit être composé de 14 chiffres"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Capital (facultatif)"
                type="number"
                v-model="modifyCompanyCapital"
                @input="$v.modifyCompanyCapital.$touch()"
                :isValid="$v.modifyCompanyCapital.$dirty ? !$v.modifyCompanyCapital.$error : null">
              </CInput>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6">
              <label>Forme sociale</label>
              <vue-bootstrap-typeahead
                  class="mt-2"
                  ref="formesocialeTypeahead"
                  v-model="modifyCompanyFormeSociale"
                  :data="companyFormeSocialeArrayOptions"
                  @hit="modifyCompanyFormeSociale = $event"
                  @input="$v.modifyCompanyFormeSociale.$touch()"
                  :isValid="$v.modifyCompanyFormeSociale.$dirty ? !$v.modifyCompanyFormeSociale.$error : null"
                   placeholder="Rentrez votre forme sociale"
                />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Précisions réglementaires (facultatif)"
                v-model="modifyCompanyPrecisionsReglementaires" type="text"
                maxlength="150"
                @input="$v.modifyCompanyPrecisionsReglementaires.$touch()"
                :isValid="$v.modifyCompanyPrecisionsReglementaires.$dirty ? !$v.modifyCompanyPrecisionsReglementaires.$error : null"
                invalid-feedback="Vos informations doivent faire entre 2 et 150 caractères"
              >
                <template #description>
                  <small class="text-info">Ces informations apparaîtront sur les factures éditées via Eclerk.</small>
                </template>
              </CInput>
            </CCol>
          </CRow>

          <form autocomplete="eclerk-no-autocomplete-input">
            <label for="eclerk-modify-company-address">Adresse</label>
            <vue-google-autocomplete
              autocomplete="eclerk-no-autocomplete-input"
              ref="eclerkModifyCompanyAddress"
              id="eclerk-modify-company-address"
              name="eclerk-modify-company-address"
              classname="form-control"
              maxlength="299"
              placeholder="Rechercher l'adresse"
              @placechanged="chooseGoogleMapsModifyCompanyAddress"
              @inputChange="modifyCompanyAddress = $event.newVal"
              :enable-geolocation='true'
            >
            </vue-google-autocomplete>
          </form>

          <CRow class="mt-4">
            <CCol sm="6">
              <CInput
                label="Email"
                type="email"
                maxlength="150"
                placeholder="Email"
                v-model="modifyCompanyEmail"
                @input="$v.modifyCompanyEmail.$touch()"
                :isValid="$v.modifyCompanyEmail.$dirty ? !$v.modifyCompanyEmail.$error : null"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <div role="group" class="form-group">
                <label for="modifyCompanyVuePhoneNumber" class>
                  Numéro de téléphone
                </label>
                <vue-phone-number-input
                  id="modifyCompanyVuePhoneNumber"
                  name="modifyCompanyVuePhoneNumber"
                  v-model="modifyCompanyPhoneNumber"
                  :default-country-code='modifyCompanyPhoneNumberResult.countryCode'
                  :preferred-countries="['FR', 'BE', 'DE']"
                  valid-color='#28a745'
                  error-color='#dc3545'
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :'
                  }"
                  @update='modifyCompanyPhoneNumberResult = $event'
                  :required='false'
                />
                <small class="text-danger" v-if="modifyCompanyPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6">
              <CInput
                label="Iban"
                type="text"
                v-mask="'NN NN NNNN NNNN NNNN NNNN NNNN NNN'"
                placeholder="XX XX XXXX XXXX XXXX XXXX XXXX XXX"
                maxlength="50"
                v-model="modifyCompanyIban"
                @input="$v.modifyCompanyIban.$touch()"
                :isValid="$v.modifyCompanyIban.$dirty ? !$v.modifyCompanyIban.$error : null"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Bic"
                type="text"
                maxlength="50"
                v-model="modifyCompanyBic"
                @input="$v.modifyCompanyBic.$touch()"
                :isValid="$v.modifyCompanyBic.$dirty ? !$v.modifyCompanyBic.$error : null"
              >
              </CInput>
            </CCol>
          </CRow>
          <CInput
            label="Titulaire du compte"
            type="text"
            maxlength="150"
            v-model="modifyCompanyTitulaire"
            @input="$v.modifyCompanyTitulaire.$touch()"
            :isValid="$v.modifyCompanyTitulaire.$dirty ? !$v.modifyCompanyTitulaire.$error : null"
          >
          </CInput>



          <CButton
            :color="modifyCompanyButtonStyle"
            shape="pill" block class="px-4"
            @click='modifyCompany'
            :disabled="isInvalidModifyCompany || modifyCompanyInProcess">
            <CSpinner size="sm" label="update company spinner" v-if="modifyCompanyInProcess"></CSpinner>
            {{ modifyCompanyButtonText }}
          </CButton>
        </CForm>

      </CCardBody>
    </CCard>


    <CCard>
      <CCardHeader>
        <strong>Votre logo</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol v-if="company.logo_thumbnail" sm="6" class="text-center">
            <strong> Votre logo actuel </strong><br>
            <img :src="baseUrl + company.logo_thumbnail" class="mt-2" style="width: 60%; max-width: 300px;"/>
          </CCol>
          <CCol v-else sm="6" class="text-center">
            <strong> Veuillez charger le logo de votre entreprise </strong>
          </CCol>
          <CCol sm="6" class="text-center align-self-center">
            <CButton
            color="outline-dark"
            shape="pill" block class="px-4"
            @click="isLogoModifying = true;">
              Charger votre logo
            </CButton>
          </CCol>
        </CRow>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric, email} from 'vuelidate/lib/validators'
import { isValidIban, isValidBic, isStringIntegerOrBlank } from '@/validators/validators'
import { file_size_validation, image_extension_validation } from '@/validators/validators'

import { APIBossConnected } from '@/api/APIBossConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

const apiBossConnected = new APIBossConnected()

export default {
  name: 'CompanyDetails',
  components: {
    Loading,
    VueBootstrapTypeahead,
    VueGoogleAutocomplete
  },
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {

      baseUrl: apiBaseUrl,
      // ---- COMPANY ---------
      isCompanyLoading: false,
      company: {

        company_name: '',
        logo: '',
        siret: '',
        forme_sociale: '',
        precisions_reglementaires: '',
        capital: null,
        address: '',
        phone_country_code: '',
        phone_number: '',
        email: '',
        iban: '',
        bic: '',
        titulaire: ''
      },

      // ---- Modify Company ---
      isCompanyModified: false,

      modifyCompanyName: '',
      modifyCompanySiret: '',
      modifyCompanyFormeSociale: '',
      modifyCompanyPrecisionsReglementaires: '',
      modifyCompanyCapital: '',
      modifyCompanyAddress: '',
      modifyCompanyPhoneNumber: null,
      modifyCompanyPhoneNumberResult: {
        countryCode: "FR",
      },
      modifyCompanyEmail: '',
      modifyCompanyIban: '',
      modifyCompanyBic: '',
      modifyCompanyTitulaire: '',

      companyFormeSocialeArrayOptions: [
        'Entreprise individuelle',
        "Entreprise unipersonnelle à responsabilité limitée",
        "Société à responsabilité limitée",
        "Société par actions simplifiée",
        "Société en nom collectif",
        "Société coopérative de production",
        "Société civile professionnelle",
      ],
      modifyCompanyButtonText: 'Modifier les informations de votre entreprise',
      modifyCompanyButtonStyle: 'outline-primary',
      modifyCompanyInProcess: false,


      // ---- MODIFY LOGO -----
      isLogoModifying: false,
      modifyLogoFile: [],
      previewImg: null,
      modifyLogoCompanyInProcess: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isCompanyLoading || this.modifyLogoCompanyInProcess) {
        return true
      }
      return false
    },
    finalModifyCompanySiret () {
      return this.modifyCompanySiret.replace(/\s/g,'');
    },
    isInvalidModifyCompany () {
      if (this.$v.modifyCompanyName.$invalid || this.$v.modifyCompanySiret.$invalid ||
      this.$v.modifyCompanyFormeSociale.$invalid || this.$v.modifyCompanyPrecisionsReglementaires.$invalid ||
      this.$v.modifyCompanyCapital.$invalid || this.$v.modifyCompanyAddress.$invalid ||
      this.$v.modifyCompanyPhoneNumberResult.$invalid || this.$v.modifyCompanyEmail.$invalid || this.$v.modifyCompanyIban.$invalid ||
      this.$v.modifyCompanyBic.$invalid || this.$v.modifyCompanyTitulaire.$invalid) {
        return true
      }
      return false
    },
  },

  watch: {
    modifyLogoFile(val) {
      if (!val) return;
      if (this.previewImg) {
        this.previewImg.remove();
      }
      const img = document.createElement("img");
      img.classList.add("obj");
      img.file = val;
      img.setAttribute('width', '150px')
      this.previewImg = img;
      this.$refs.preview.appendChild(img);

      const fileReader = new FileReader();
      fileReader.onload = (e) => { this.previewImg.src = e.target.result; };
      fileReader.readAsDataURL(val);
    }
  },


  validations: {
    modifyCompanyName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255)
    },
    modifyCompanySiret: {
      required,
      minLength: minLength(17),
      maxLength: maxLength(17),
      validSiret(value) {
        return isStringIntegerOrBlank(value)
      },
    },
    modifyCompanyFormeSociale: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    modifyCompanyPrecisionsReglementaires: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    modifyCompanyCapital: {
      numeric,
      maxLength: maxLength(149),
      minLength: minLength(2)
    },
    modifyCompanyAddress: {
      required,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    modifyCompanyPhoneNumberResult: {
      countryCode: {
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          return value
        }
      }
    },
    modifyCompanyEmail: {
      required,
      email,
      minLength: minLength(2),
      maxLength: maxLength(255)
    },
    modifyCompanyIban: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(300),
      validIban(value) {
        return isValidIban(value)
      }
    },
    modifyCompanyBic: {
      required,
      validLength(value) {
        if (value.length === 8 || value.length === 11) {
          return true
        }
        return false
      },
      validBic(value) {
        return isValidBic(value)
      }
    },
    modifyCompanyTitulaire: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },

    modifyLogoFile: {
      required,
      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      },
      file_size_validation,
      image_extension_validation,
    }
  },

  created: function() {
    this.getCompany()
  },

  methods: {
    // ------------- Getters --------------
    getCompany () {
      this.isCompanyLoading = true
      apiBossConnected.getCompany(this.token)
      .then((result) => {
        this.company = result.data
        this.modifyCompanyName = this.company.company_name
        this.modifyCompanySiret = this.company.siret
        this.modifyCompanyFormeSociale = this.company.forme_sociale
        this.$refs.formesocialeTypeahead.inputValue = this.company.forme_sociale
        this.modifyCompanyPrecisionsReglementaires = this.company.precisions_reglementaires
        this.modifyCompanyCapital = this.company.capital
        this.modifyCompanyAddress = this.company.address
        this.$refs.eclerkModifyCompanyAddress.update(this.company.address)

        this.modifyCompanyPhoneNumber = this.company.phone_number
        this.modifyCompanyPhoneNumberResult = {
          countryCode: this.company.phone_country_code,
          isValid: true,
          nationalNumber: this.company.phone_number
        }

        this.modifyCompanyEmail = this.company.email
        this.modifyCompanyIban = this.company.iban
        this.modifyCompanyBic = this.company.bic
        this.modifyCompanyTitulaire = this.company.titulaire
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCompanyLoading = false
      })
    },

    // ---------- Modify company -----------
    modifyCompany () {
      this.modifyCompanyButtonText = 'Modification en cours'
      this.modifyCompanyButtonStyle = 'secondary'
      this.modifyCompanyInProcess = true
      apiBossConnected.modifyCompany(this.token,
        this.modifyCompanyName, this.finalModifyCompanySiret, this.modifyCompanyFormeSociale, this.modifyCompanyPrecisionsReglementaires,
        this.modifyCompanyCapital, this.modifyCompanyAddress, this.modifyCompanyPhoneNumberResult.countryCode,
        this.modifyCompanyPhoneNumberResult.nationalNumber,
        this.modifyCompanyIban, this.modifyCompanyBic, this.modifyCompanyTitulaire)
      .then((result) => {
        this.company = result.data
        this.$store.commit('COMPANY_SUCCESS', result.data)
        this.isCompanyModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyCompanyButtonText = 'Modifier les informations de votre entreprise'
        this.modifyCompanyButtonStyle = 'outline-dark'
        this.modifyCompanyInProcess = false
      })
    },

    chooseGoogleMapsModifyCompanyAddress: function (addressData) {
      this.modifyCompanyAddress = addressData['street_number'] + ', ' + addressData['route'] + ', ' + addressData['locality'] + ', ' + addressData['country'];
    },


    // ---------- Modify logo company -----------

    modifyLogoCompany () {
      this.modifyLogoCompanyInProcess = true
      apiBossConnected.modifyLogoCompany(this.token, this.modifyLogoFile)
      .then((result) => {
        this.company = result.data
        this.$store.commit('LOGO_SUCCESS', result.data)
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyLogoCompanyInProcess = false
        this.isLogoModifying = false
      })
    },
  }
}
</script>

<style>
.dragdrop .custom-file,
.dragdrop .custom-file-input {
  height: 100px;
}

.dragdrop .custom-file-label {
  border: 0;
  border: 2px dotted;
  height: 100px;
  line-height: 90px;
  text-align: center;
  padding: 0;
}

.dragdrop .custom-file:hover .custom-file-label {
  background: #321fdb;
  color: #fff;
}

.dragdrop .custom-file-label::after {
  display: none;
}
</style>
